@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900;1,300&display=swap);
/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: linear-gradient(
		192.06deg,
		#7900f2 15.54%,
		#ba00cb 65.91%,
		#ff3bb7 98.66%
	);
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(
		192.06deg,
		#7900f2 15.54%,
		#ba00cb 65.91%,
		#ff3bb7 98.66%
	);
}
.main__content {
	background: #000000;
}
.bg__primary {
	background: #7900f2 !important;
}
.text__pink {
	color: #ff3bb7 !important;
}
.pointer {
	cursor: pointer;
}
*:focus {
	border-color: none !important;
	box-shadow: none !important;
}
.custom__container {
	padding: 5px 70px;
}
@media (max-width: 767.98px) {
	.custom__container {
		padding: 5px 20px;
	}
}
.logo__title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.04em;
	color: #ffffff;
}
.custom__nav .nav-item .nav-link {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.03em;
	color: #ffffff !important;
}

.open__app-btn {
	background: linear-gradient(
		192.06deg,
		#7900f2 15.54%,
		#ba00cb 65.91%,
		#ff3bb7 98.66%
	);
	border-radius: 11px;
	border: 0 !important;
	padding: 15px 20px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.06em;
	color: #ffffff !important;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.main__header {
	margin-top: 50px;
	padding: 5px 90px;
}
@media (max-width: 767.98px) {
	.main__header {
		margin-top: 30px;
		padding: 5px 40px;
	}
}
.main__header-top-tagline {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	line-height: 30px;
	letter-spacing: -0.04em;
	color: #ffbc10;
}
.main__header-heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 51px;
	/* or 142% */
	letter-spacing: -0.04em;
	color: #ffffff;
}
.main__header-subtitle {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 15px;
	letter-spacing: -0.04em;
	color: #596067;
}
@media (max-width: 575.98px) {
	.main__header-top-tagline {
		font-size: 18px;
		line-height: 30px;
	}
	.main__header-heading {
		font-size: 26px;
		line-height: 51px;
	}
	.main__header-subtitle {
		font-size: 14px;
		line-height: 15px;
	}
}
.buypayc__container {
	margin-top: 150px;
}
.btn__buy {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 26px;
	/* identical to box height */
	letter-spacing: -0.06em;
	color: #ffffff !important;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	background: #7900f2 !important;
	border-radius: 11px !important;
	border: 0 !important;
	padding: 10px 25px;
}

.about__payc-btn {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 26px;
	letter-spacing: -0.06em;
	color: #ffffff !important;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media (max-width: 575.98px) {
	.btn__buy {
		font-size: 14px;
		line-height: 26px;
		padding: 6px 25px;
	}

	.about__payc-btn {
		font-size: 14px;
		line-height: 26px;
	}
}
.bg__buy-payc {
	position: relative;
	padding: 80px 90px;
	background: linear-gradient(
		to bottom,
		rgba(255, 59, 183, 0.26),
		rgba(53, 0, 61, 0.26),
		rgba(121, 0, 242, 0.26)
	);
	-webkit-clip-path: polygon(100% 1%, 100% 91%, 0 100%, 0 16%);
	clip-path: polygon(100% 1%, 100% 91%, 0 100%, 0 16%);
}
.chevron__down {
	position: absolute;
	left: 50%;
	top: 40px;
	cursor: pointer;
}
.payc__info-top-tagline {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	line-height: 65px;
	letter-spacing: -0.04em;
	text-transform: uppercase;
	color: #ffbc10;
}
.payc__info-heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 63px;
	text-align: center;
	letter-spacing: -0.04em;
	color: #ffffff;
}

.payc__info-number {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 79px;
	line-height: 63px;
	text-align: center;
	letter-spacing: -0.01em;
	background-color: #7900f2;
	background-image: linear-gradient(45deg, #7900f2, #e000ff, #ff3bb7);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	/* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); */
}
.payc__info-desc {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 65px;
	/* identical to box height, or 271% */
	letter-spacing: -0.04em;
	color: #ffffff !important;
}
.payc__info-our-contract {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 65px;
	/* identical to box height, or 241% */
	letter-spacing: -0.04em;
	color: #ffffff;
	border: 5px solid !important;
	border-width: 3px !important;
	border-image: linear-gradient(to left, #e000ff, #7900f2) 1 !important;

	/* width: 75%; */
}
.payc__info-our-contract span {
	font-size: 30px;
	font-weight: 600 !important;
	background-color: #7900f2;
	background-image: linear-gradient(45deg, #7900f2, #e000ff, #ff3bb7);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
@media (max-width: 1025.98px) {
	.payc__info-top-tagline {
		font-size: 16px;
		line-height: 45px;
	}
	.payc__info-heading {
		font-size: 40px;
		line-height: 63px;
	}
	.payc__info-our-contract {
		font-size: 18px;
		line-height: 45px;
	}
	.payc__info-our-contract span {
		font-size: 18px;
		line-height: 45px;
	}
}
@media (max-width: 767.98px) {
	.buypayc__container {
		margin-top: 75px;
	}
	.payc__info-top-tagline {
		font-size: 14px;
		line-height: 35px;
	}
	.payc__info-heading {
		font-size: 30px;
		line-height: 45px;
	}
	.payc__info-number {
		font-size: 59px;
		line-height: 53px;
	}
	.payc__info-desc {
		font-size: 18px;
		line-height: 35px;
	}
	.payc__info-our-contract {
		font-size: 16px;
		line-height: 45px;
	}
	.payc__info-our-contract span {
		font-size: 16px;
		line-height: 45px;
	}
}
@media (max-width: 575.98px) {
	.bg__buy-payc {
		padding: 80px 20px;
	}
	.payc__info-top-tagline {
		font-size: 12px;
		line-height: 35px;
	}
	.payc__info-heading {
		font-size: 26px;
		line-height: 35px;
	}
	.payc__info-number {
		font-size: 26px;
		line-height: 35px;
	}
	.payc__info-desc {
		font-size: 12px;
		line-height: 35px;
	}
	.payc__info-our-contract {
		font-size: 8px;
		line-height: 15px;
	}
	.payc__info-our-contract span {
		font-size: 10px;
		line-height: 15px;
	}
	.chevron__down {
		/* top: 30px; */
		position: relative;
		left: 0%;
		top: 80px;
		cursor: pointer;
	}
}

.tokenomic__container {
	margin-top: 250px;
	padding: 5px 90px;
}
.tokenomic__top-line {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 65px;
	/* or 271% */
	letter-spacing: -0.04em;
	color: #ffbc10;
}
.tokenomic__heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 79px;
	line-height: 63px;
	/* or 80% */
	text-align: center;
	letter-spacing: -0.04em;
	color: #a24df7;
}

.tokenomic__desc {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	/* or 167% */
	text-align: center;
	letter-spacing: -0.04em;
	color: #ffffff;
}
.progress__line {
	color: #a24df7;
	font-weight: 800;
	font-size: 16px;
}
.tokenomic__fee-info {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 600;
	font-size: 20px;
	line-height: 40px;
	/* or 150% */
	text-align: center;
	letter-spacing: -0.04em;
	color: #ffffff;
}
.tokenomic__fee-info span {
	color: #a24df7;
}
.circle__wrapper {
	width: 138px;
	height: 138px;
}
.vertical__stroke {
	margin-top: -267px;
	padding: 150px;
	background: linear-gradient(
		to right,
		rgba(255, 59, 183, 0.75),
		rgba(186, 0, 203, 1),
		rgba(121, 0, 242, 0.75)
	);
	-webkit-clip-path: polygon(0 23%, 100% 70%, 100% 81%, 0 35%);
	clip-path: polygon(0 23%, 100% 70%, 100% 81%, 0 35%);
}
.vertical__stroke1 {
	margin-top: 40px;
	padding: 150px;
	background: linear-gradient(
		to right,
		rgba(102, 0, 205, 0.75),
		rgba(158, 2, 172, 1),
		rgba(255, 0, 161, 0.75)
	);
	-webkit-clip-path: polygon(0 23%, 100% 70%, 100% 81%, 0 35%);
	clip-path: polygon(0 23%, 100% 70%, 100% 81%, 0 35%);
}
@media (max-width: 767.98px) {
	.tokenomic__container {
		padding: 5px 40px;
		margin-top: 75px;
	}
	.tokenomic__top-line {
		font-size: 20px;
		line-height: 35px;
	}
	.tokenomic__heading {
		font-size: 59px;
	}
	.tokenomic__desc {
		font-size: 14px;
		line-height: 30px;
	}
	.circle__wrapper {
		width: 108px;
		height: 108px;
	}
	.progress__line {
		font-weight: 700;
		font-size: 12px;
	}
	.tokenomic__fee-info {
		font-size: 16px;
		line-height: 40px;
	}
	.vertical__stroke {
		margin-top: -267px;
	}
	.vertical__stroke1 {
		margin-top: 20px;
	}
}
@media (max-width: 575.98px) {
	.tokenomic__top-line {
		font-size: 14px;
		line-height: 15px;
	}
	.tokenomic__heading {
		font-size: 36px;
	}
	.tokenomic__desc {
		font-size: 14px;
		line-height: 20px;
	}
	.tokenomic__fee-info {
		font-size: 14px;
		line-height: 25px;
	}
}
.section__heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 70px;
	line-height: 63px;
	text-align: center;
	letter-spacing: -0.04em;
	color: #ffffff;
}
.section__heading span {
	background-color: #7900f2;
	background-image: linear-gradient(45deg, #7900f2, #e000ff, #ff3bb7);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
@media (max-width: 1025.98px) {
	.section__heading {
		font-size: 60px;
		line-height: 57px;
	}
}
@media (max-width: 767.98px) {
	.section__heading {
		font-size: 40px;
		line-height: 47px;
	}
}
@media (max-width: 575.98px) {
	.section__heading {
		font-size: 36px;
		line-height: 35px;
	}
}
.roadmap__container {
	margin-top: 250px;
	padding: 5px 90px;
}

.vertical-timeline-element-icon {
	margin-left: -15px !important;
	width: 29.6px !important;
	height: 29.6px !important;
	margin-top: 80px;
}
@media (max-width: 1169.98px) {
	.vertical-timeline-element-icon {
		margin-left: 5px !important;
	}
}
.vertical-timeline-element-content {
	background: transparent !important;
	border: 0 !important;
	box-shadow: 0 0px 0 #ddd !important;
}
.roadmap__content {
	position: relative;
}
.roadmap__content img {
	position: absolute;
}
.roadmap__child-content {
	margin-left: 70px;
}
.roadmap__child-content h2 {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 63px;
	letter-spacing: -0.04em;
	color: #a24df7;
}
.roadmap__child-content p {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 30px;
	/* or 167% */
	letter-spacing: -0.04em;
	color: #ffffff;
}
.roadmap__child-content span {
	color: #ffbc10 !important;
}
@media (max-width: 767.98px) {
	.roadmap__container {
		margin-top: 75px;
	}
}
@media (max-width: 575.98px) {
	.roadmap__container {
		padding: 5px 30px;
	}
	.roadmap__child-content {
		margin-left: 10px;
	}
}
.advantage__container {
	margin-top: 250px;
	padding: 5px 90px;
}
.table__card {
	background: #0f0f0f !important;
	border-radius: 29px !important;
	border: 0 !important;
}
.table__card thead {
	background: linear-gradient(
		to right,
		#7900f2 15.54%,
		#ba00cb 65.91%,
		#ff3bb7 98.66%
	);
	border-radius: 14px;
}
.table__card thead th {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
	padding: 20px 0px;
	/* identical to box height */
	letter-spacing: -0.03em;
	color: #ffffff;
}
.first__td h2 {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 33px;
	/* identical to box height */
	letter-spacing: -0.03em;
	color: #7900f2;
}
.first__td small {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	letter-spacing: -0.03em;
	color: #ffffff;
	opacity: 0.66;
}
.second__td {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 33px;
	/* identical to box height */
	letter-spacing: -0.03em;
	background-color: #7900f2;
	background-image: linear-gradient(45deg, #7900f2, #e000ff, #ff3bb7);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.simple__td {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 33px;
	/* identical to box height */
	letter-spacing: -0.03em;
	color: #ffffff;
	text-align: left;
}
@media (max-width: 767.98px) {
	.advantage__container {
		padding: 5px 40px;
		margin-top: 100px;
	}
	.table__card thead th {
		font-size: 20px;
		line-height: 30px;
	}
	.first__td h2 {
		font-size: 18px;
		line-height: 27px;
	}
	.first__td small {
		font-size: 14px;
		line-height: 24px;
	}
	.second__td {
		font-size: 18px;
		line-height: 27px;
	}
	.simple__td {
		font-size: 18px;
		line-height: 27px;
	}
}
.how__container {
	padding: 5px 90px;
	margin-top: 350px;
}

.how__work-heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 70px;
	line-height: 63px;
	/* or 90% */
	letter-spacing: -0.04em;
	background-color: #7900f2;
	background-image: linear-gradient(45deg, #7900f2, #e000ff, #ff3bb7);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.how__work-desc {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	/* or 188% */
	letter-spacing: -0.04em;
	color: #ffffff;
}
.fire__card {
	background: #7900f2 !important;
	border-radius: 15px !important;
	border: 0 !important;
}
.fire__card h2 {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 20px;
	/* or 188% */
	letter-spacing: -0.04em;
	color: #ffffff;
}
.fire__card p {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	/* or 188% */
	letter-spacing: -0.04em;
	color: #ffffff;
}
.simple__mini-card {
	background: #ffffff !important;
	border-radius: 15px !important;
	border: 0 !important;
}
.simple__mini-card h2 {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	/* or 350% */
	text-align: left;
	margin-top: 20px;
	letter-spacing: -0.04em;
	color: #000000;
}
.simple__mini-card p {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	/* or 142% */

	letter-spacing: -0.04em;

	color: #000000;
}
@media (max-width: 767.98px) {
	.how__container {
		margin-top: 100px;
		padding: 5px 40px;
	}
	.how__work-heading {
		font-size: 40px;
		line-height: 43px;
		text-align: center;
	}
	.fire__card h2 {
		font-size: 24px;
		line-height: 20px;
	}
}
@media (max-width: 575.98px) {
	.how__work-heading {
		font-size: 36px;
		line-height: 25px;
	}
}
.faq__container {
	margin-top: 250px;
	padding: 5px 90px;
}

.faq__tagline {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 30px;
	/* identical to box height, or 107% */
	text-align: center;
	letter-spacing: -0.04em;
	color: #ffffff;
}
.faq__heading-active {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 30px;
	/* identical to box height, or 100% */
	letter-spacing: -0.04em;
	background-color: #7900f2;
	background-image: linear-gradient(45deg, #7900f2, #e000ff, #ff3bb7);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.faq__heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 30px;
	/* identical to box height, or 100% */
	letter-spacing: -0.04em;
	color: white !important;
}
.active__hr {
	background: linear-gradient(
		to right,
		#7900f2 15.54%,
		#ba00cb 65.91%,
		#ff3bb7 98.66%
	);
	margin-top: 5px;
	height: 2px;
}
.simple__hr {
	margin-top: 5px;
	background: white;
	height: 2px;
}
.faq__content {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	/* or 150% */
	text-align: left;
	letter-spacing: -0.04em;

	color: #ffffff;
}
@media (max-width: 767.98px) {
	.faq__container {
		padding: 5px 40px;
		margin-top: 75px;
	}
	.faq__tagline {
		font-size: 18px;
		line-height: 30px;
	}
	.faq__heading-active {
		font-size: 24px;
	}
	.faq__heading {
		font-size: 24px;
	}
	.faq__content {
		font-size: 18px;
	}
}
@media (max-width: 575.98px) {
	.faq__container {
		padding: 5px 20px;
	}
	.faq__container img {
		height: 36px;
	}
	.faq__tagline {
		font-size: 14px;
		line-height: 30px;
	}
	.faq__heading-active {
		font-size: 16px;
	}
	.faq__heading {
		font-size: 16px;
	}
	.faq__content {
		font-size: 14px;
	}
}

.team__container {
	padding: 5px 90px;
	margin-top: 250px;
}
.team__heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 70px;
	line-height: 63px;
	/* or 90% */

	text-align: center;
	letter-spacing: -0.04em;
	color: #aa54ff;
}
.team__row {
	margin-top: 120px;
}
.team__card {
	background: transparent !important;
	border: 0 !important;
}
.social__icon {
	width: 57.94px;
	height: 52.81px;
}
.team__img {
	height: 300.26px;
	background: #ffffff;
	/* border-radius: 27px; */
	border: 5px solid !important;
	border-width: 5px !important;
	border-image: linear-gradient(to left, #e000ff, #7900f2) 1 !important;
}
.team__name {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	line-height: 63px;
	/* identical to box height, or 126% */

	text-align: center;
	letter-spacing: -0.04em;

	color: #ffbc10;
}
.team__rank {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 63px;
	/* or 210% */

	text-align: center;
	letter-spacing: -0.04em;

	color: #d3d3d3;
}
@media (max-width: 767.98px) {
	.team__container {
		padding: 5px 40px;
		margin-top: 75px;
	}
	.team__heading {
		font-size: 40px;
		line-height: 43px;
	}
	.team__row {
		margin-top: 60px;
	}
	.team__name {
		font-size: 24px;
		line-height: 43px;
	}
	.team__rank {
		font-size: 20px;
		line-height: 43px;
	}
}
@media (max-width: 575.98px) {
	.team__heading {
		font-size: 36px;
		line-height: 25px;
	}
	.team__row {
		margin-top: 60px;
	}
	.team__name {
		font-size: 20px;
		line-height: 25px;
	}
	.team__rank {
		font-size: 16px;
		line-height: 25px;
	}
	.social__icon {
		width: 32.94px;
		height: 32.81px;
	}
}
.footer {
	background: linear-gradient(
		to right,
		#7900f2 -9.47%,
		#e000ff 53.8%,
		#ff3bb7 113.75%
	);
	margin-top: 250px;
}
.footer h2 {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
	/* identical to box height */

	letter-spacing: -0.03em;

	color: #ffffff;
}

.footer__container {
	padding: 20px 90px;
}
@media (max-width: 767.98px) {
	.footer {
		margin-top: 75px;
	}
	.footer__container {
		padding: 5px 20px;
	}
}
@media (max-width: 575.98px) {
	.footer h2 {
		font-size: 16px;
		line-height: 15px;
	}
	.footer img {
		height: 20px;
	}
}

/* Dashboard */

.dashboard__sidebar {
	min-height: 100vh;
	background: #282828;
}
.dashboard__content {
	min-height: 100vh;
	background: #0d0d0d;
}

.vertical__nav .dashboard__logo {
	margin-top: 30px;
	padding: 40px 45px;
}

.vertical__nav .nav-item .nav-link {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	color: #ffffff;
	opacity: 0.7;
	padding: 15px 30px;
}
.vertical__nav .active__link {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	color: #ffffff;
	opacity: 1 !important;
	background: rgba(121, 0, 242, 0.21);
	border-left: 3px solid #7900f2;
	padding: 15px 30px;
}
@media (max-width: 575.98px) {
	.vertical__nav .dashboard__logo {
		height: 70px;
		margin-top: 35px;
		padding: 0px;
	}
	.first__nav-item {
		margin-top: 100px;
	}
	.vertical__nav .nav-item .nav-link {
		padding: 16px;
	}
	.vertical__nav .active__link {
		padding: 16px;
	}
}
.nav__payc {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 24px;
	background-color: #7900f2;
	background-image: linear-gradient(
		to right,
		#7900f2,
		#e000ff,
		#ff3bb7
	) !important;
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.dashboard__heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 45px;
	/* identical to box height */
	color: #e8e8e8;
}
.connect__wallet {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	letter-spacing: -0.06em;
	color: #ffffff;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	background: linear-gradient(
		to right,
		#7900f2 15.54%,
		#ba00cb 65.91%,
		#ff3bb7 98.66%
	) !important;
	border: 0 !important;
	padding: 15px;
	border-radius: 13px !important;
}
.active__card {
	background: #230a3d;
	border-radius: 21px;
	padding: 40px;
	text-align: center;
	border: 2px solid rgba(224, 0, 255, 1) !important;
}
.inactive__card {
	background: #151515;
	border-radius: 21px;
	padding: 40px;
	text-align: center;
}
.card__title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	color: #e8e8e8;
	opacity: 0.7;
}
.card__price {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height */
	color: #e8e8e8;
	opacity: 1;
}
.claim__row {
	background: #230a3d;
	border-radius: 21px;
	padding: 20px 0;
	border: 2px solid rgba(224, 0, 255, 1) !important;
}
.reward__tagline {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	text-align: center;
	color: #ffffff;
}
.reward__price {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 123%;
	/* or 25px */
	text-align: center;
	letter-spacing: -0.03em;
	color: #ffffff;
}
.reward__subline {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 33px;
	/* identical to box height, or 236% */
	text-align: center;
	letter-spacing: -0.01em;
	color: #ffffff;
}
.dao__coming {
	background: #151515;
	box-shadow: 0px 4px 4px #000000;
	border-radius: 21px;
	border: 1px solid rgba(255, 188, 16, 0.7);
	text-align: center;
	padding: 40px;
}
.dao__tagline {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 110.5%;
	/* or 33px */
	text-align: center;
	letter-spacing: -0.03em;
	background-color: #7900f2;
	background-image: linear-gradient(
		to right,
		#ffbc10 -9.47%,
		#ff00a1 53.8%,
		#7900f2 113.75%
	) !important;
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.dao__soon {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 110.5%;
	/* identical to box height, or 40px */
	text-align: center;
	letter-spacing: -0.03em;
	color: #ffbc10;
}
.dao__coming strong {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 135.5%;
	/* or 27px */
	text-align: center;
	letter-spacing: -0.03em;
	color: #ffffff;
}
.dao__coming small {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135.5%;
	/* or 27px */

	text-align: center;
	letter-spacing: -0.03em;

	color: #ffffff;
}
.dao__btn {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 135.5%;
	/* identical to box height, or 22px */
	text-align: center;
	letter-spacing: -0.03em;
	color: #ffffff !important;
	background: linear-gradient(
		to right,
		#ffbc10 4.96%,
		#ff00a1 54.85%,
		#7900f2 102.1%
	);
	border-radius: 13px;
	border: 0 !important;
	padding: 15px 35px;
}

.game__coming {
	background: #151515;
	box-shadow: 0px 4px 4px #000000;
	border-radius: 21px;
	border: 1px solid rgba(255, 59, 183, 0.7);
	text-align: center;
	padding: 40px;
}
.game__tagline {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 110.5%;
	/* or 33px */
	text-align: center;
	letter-spacing: -0.03em;
	background-color: #7900f2;
	background-image: linear-gradient(
		to right,
		#ff3bb7 -9.47%,
		#e000ff 53.8%,
		#ff3bb7 113.75%
	) !important;
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.game__soon {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 110.5%;
	/* identical to box height, or 40px */
	text-align: center;
	letter-spacing: -0.03em;
	color: #ff3bb7;
}

.game__coming strong {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 135.5%;
	/* or 27px */
	text-align: center;
	letter-spacing: -0.03em;
	color: #ffffff;
}
.game__coming small {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135.5%;
	/* or 27px */

	text-align: center;
	letter-spacing: -0.03em;

	color: #ffffff;
}
.game__btn {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 135.5%;
	/* identical to box height, or 22px */
	text-align: center;
	letter-spacing: -0.03em;
	color: #ffffff !important;
	background: linear-gradient(
		to right,
		#ff3bb7 5.69%,
		#e000ff 57.42%,
		#ff3bb7 106.43%
	);
	border-radius: 13px;
	border: 0 !important;
	padding: 15px 35px;
}
.calculator__row {
	background: #151515;
	border-radius: 21px;
}
.calculator__row label {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	text-align: center;
	color: #ffffff;
}
.calculator__row .input-group input {
	background: #e2e2e2 !important;
	/* drop my shadow */
	height: 37.92px;
	box-shadow: 0px 4px -10px 3px rgba(0, 0, 0, 0.31) !important;
	border-radius: 10px 0px 0px 10px !important;
	border: 1px solid rgba(255, 59, 183, 1);
	border-right: none !important;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #000000;
}
.calculator__row .input-group .input-group-text {
	background: #e2e2e2 !important;
	/* drop my shadow */
	height: 37.92px;
	border-radius: 0px 10px 10px 0px !important;
	border: 1px solid rgba(255, 59, 183, 1);
	border-left: none !important;
}
.calculator__row .input-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	letter-spacing: -0.06em;
	background-color: #7900f2;
	background-image: linear-gradient(
		to right,
		#7900f2 -9.47%,
		#e000ff 53.8%,
		#ff3bb7 113.75%
	) !important;
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.btn__range {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	text-align: center;
	color: #000000;
	background: #e2e2e2 !important;
	border: 0 !important;
	width: 54.95px;
	height: 37.92px;
	box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.31) !important;
	border-radius: 10px !important;
}
.btn__calculate {
	background: #7900f2 !important;
	/* drop my shadow */

	box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.31) !important;
	border-radius: 10px !important;
	border: 0 !important;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	color: #ffffff;
}

.nav-item{
	text-decoration: none;
	color:#FFFFFF;
}

.nav-item:hover{
	text-decoration: none;
	color:#FFFFFF;
}
